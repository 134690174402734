import payload_plugin_0feN5Fpol_sXs9BFUEaHNpTolJEESV7GZ2S4NJsDVLw from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.3_vue@3.5.13_typescript@5.8.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_whPO__1cGUiobe5OJ6Sk8OrVd8LbJkolyDcCam1WZLw from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._5fd424220c2ad888c9d8ce546ac753a9/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hISn38_nVyATRoq7x2VYpqCyaUhOCv9YsV5kLoWdskc from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._5fd424220c2ad888c9d8ce546ac753a9/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_F0wmP2UCLE_rWzaNVXJBq4EVKUU8Fr99jijoJld9rXs from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._5fd424220c2ad888c9d8ce546ac753a9/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_t_20EjrPYtBxHcaanxyBkHA11fp4PgBILY5OaX9TplM from "/app/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_0Y6aQkF4nNPizzn3tKyT25QPsQ05Eg5Y0HRbn5TPreY from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._5fd424220c2ad888c9d8ce546ac753a9/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_fVu_BNjZo5Sc7C5pxzCR1exT0eyeK46dDdWE7tb5wCE from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._5fd424220c2ad888c9d8ce546ac753a9/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_x21HhzIHkr_OIsiD_1wYOHeL2EBnk16oZRPGVmLCfgM from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._5fd424220c2ad888c9d8ce546ac753a9/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_AzdWdTSW5znwI6IzQEj1je5lnmSGjNs1BTekmEh5tgA from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._5fd424220c2ad888c9d8ce546ac753a9/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_0Nj3sfsg242ar3vg5MO2TJdPc9zwb9CB7Ee47rRu9TU from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.3_vue@3.5.13_typescript@5.8.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import switch_locale_path_ssr_84XjyO62o_dnt18YVhDWwSdP9L3aHosU3iEyOFVgnnQ from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_7d46480f789fbc3c0c03ab3914a9d5d9/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_TUiQAPf2SdzGXobyYFYSw6IR4twDvq4AMUDk4m4_PGs from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_7d46480f789fbc3c0c03ab3914a9d5d9/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_eC7ASd2Hlppk6r_3Sps66Bd7We8DAr66f29O3jw3_aY from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_7d46480f789fbc3c0c03ab3914a9d5d9/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/app/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_OZtKJOZATJMin3xy0otxclXsGzbhR2b2Pa63kpPIRi8 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._5fd424220c2ad888c9d8ce546ac753a9/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import session_client_rgW9efoHlJLyoYuWLOXaboQyWkE81KlH1mCADLDY0X0 from "/app/node_modules/.pnpm/nuxt-auth-utils@0.5.18_magicast@0.3.5/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug from "/app/node_modules/.cache/nuxt/.nuxt/pwa-icons-plugin.ts";
import siteConfig_WLRsgFX41qwvXW_n56kgpsvTvfJzKSPRdEIN_96jq_s from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_8eGNY4aKqHagj3_M7ifTO29_rMZ19XxC593WzRaAL0I from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_NIm1AJ0nXsnhxVAfrITSrVsOhVtZajxe13qA7Ist1eM from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_rehrK2s_0_Uqgu1pNGRCcRMJYberUwWbQmusYINRVhc from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_3UpAgUw_HK2N1kE4P3Zh0nDg8OhDef_XcpZxdXWckLI from "/app/node_modules/.pnpm/nuxt-site-config@3.1.9_magicast@0.3.5_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_muRHZgGuvZ2U31KIkCbKXRuy_fcrYEK81_9AMre3on0 from "/app/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.5_@types+node@22.14.0_jiti@2.4.2_lightningcss_034f465b7f2ea6560d9055b1ece56a96/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import colors_8jqpJyF_JLloXpWfF6gUeWsTgoPUzzLh2G_SlYkPXrU from "/app/node_modules/.pnpm/@nuxt+ui@3.0.2_@babel+parser@7.27.0_db0@0.3.1_embla-carousel@8.6.0_ioredis@5.6.0_magica_32c3f46776272ede51ba0aba4f65b429/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_k3O6K7xPbZLksPX9eFeByABDUMC2ukizyuhyjkZ4g2M from "/app/runtime/cookies/plugin.ts";
import auth_H8gVM_hLMR4kH70PZQlWNqJIB9Rdr0rL0wFPPsoYoYA from "/app/app/plugins/auth.ts";
import pwa_client_dz_o2T1TlNigVzUPHHmoPG0Xf8XEYsQJyd6SpFj6pXo from "/app/app/plugins/pwa.client.ts";
import setup_2EiPydRvE4hXvMSbzmJ6Lc96jn_E9AtAJl4TFsO4u_M from "/app/app/plugins/setup.ts";
import vee_validate_prVYiciHtOTdOeHtP1m_5DAlIWTV6yazOoBlP_BKG58 from "/app/app/plugins/vee-validate.ts";
import websocket_client_ZhPy2_2UAj0pWmdohDN16DyMwmaeAkAVApkROSEoIrQ from "/app/app/plugins/websocket.client.ts";
import ssg_detect_DCGLif7b0LpjeeLWFkoeqBCHXK3NYdKmnUr8OsQOt0Y from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_ro_7d46480f789fbc3c0c03ab3914a9d5d9/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
import pwa_client_WyqWxI8CjoM6ZeyXY6GfzW9KWGr2CcrAuVn1egZuUWI from "/app/node_modules/.pnpm/@vite-pwa+nuxt@1.0.0_magicast@0.3.5_vite@6.2.5_@types+node@22.14.0_jiti@2.4.2_lightning_c4b19ae298842f6e652478ef6b7b00ac/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _0_routeRules_iQCBtc8UFu0kTz2BZTZyBmdlFAJM0VLWSmMrE_7GMTo from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
export default [
  payload_plugin_0feN5Fpol_sXs9BFUEaHNpTolJEESV7GZ2S4NJsDVLw,
  revive_payload_client_whPO__1cGUiobe5OJ6Sk8OrVd8LbJkolyDcCam1WZLw,
  unhead_hISn38_nVyATRoq7x2VYpqCyaUhOCv9YsV5kLoWdskc,
  router_F0wmP2UCLE_rWzaNVXJBq4EVKUU8Fr99jijoJld9rXs,
  _0_siteConfig_t_20EjrPYtBxHcaanxyBkHA11fp4PgBILY5OaX9TplM,
  payload_client_0Y6aQkF4nNPizzn3tKyT25QPsQ05Eg5Y0HRbn5TPreY,
  navigation_repaint_client_fVu_BNjZo5Sc7C5pxzCR1exT0eyeK46dDdWE7tb5wCE,
  check_outdated_build_client_x21HhzIHkr_OIsiD_1wYOHeL2EBnk16oZRPGVmLCfgM,
  chunk_reload_client_AzdWdTSW5znwI6IzQEj1je5lnmSGjNs1BTekmEh5tgA,
  plugin_vue3_0Nj3sfsg242ar3vg5MO2TJdPc9zwb9CB7Ee47rRu9TU,
  switch_locale_path_ssr_84XjyO62o_dnt18YVhDWwSdP9L3aHosU3iEyOFVgnnQ,
  route_locale_detect_TUiQAPf2SdzGXobyYFYSw6IR4twDvq4AMUDk4m4_PGs,
  i18n_eC7ASd2Hlppk6r_3Sps66Bd7We8DAr66f29O3jw3_aY,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_OZtKJOZATJMin3xy0otxclXsGzbhR2b2Pa63kpPIRi8,
  session_client_rgW9efoHlJLyoYuWLOXaboQyWkE81KlH1mCADLDY0X0,
  pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug,
  siteConfig_WLRsgFX41qwvXW_n56kgpsvTvfJzKSPRdEIN_96jq_s,
  inferSeoMetaPlugin_8eGNY4aKqHagj3_M7ifTO29_rMZ19XxC593WzRaAL0I,
  titles_NIm1AJ0nXsnhxVAfrITSrVsOhVtZajxe13qA7Ist1eM,
  defaultsWaitI18n_rehrK2s_0_Uqgu1pNGRCcRMJYberUwWbQmusYINRVhc,
  i18n_3UpAgUw_HK2N1kE4P3Zh0nDg8OhDef_XcpZxdXWckLI,
  plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk,
  plugin_muRHZgGuvZ2U31KIkCbKXRuy_fcrYEK81_9AMre3on0,
  colors_8jqpJyF_JLloXpWfF6gUeWsTgoPUzzLh2G_SlYkPXrU,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_k3O6K7xPbZLksPX9eFeByABDUMC2ukizyuhyjkZ4g2M,
  auth_H8gVM_hLMR4kH70PZQlWNqJIB9Rdr0rL0wFPPsoYoYA,
  pwa_client_dz_o2T1TlNigVzUPHHmoPG0Xf8XEYsQJyd6SpFj6pXo,
  setup_2EiPydRvE4hXvMSbzmJ6Lc96jn_E9AtAJl4TFsO4u_M,
  vee_validate_prVYiciHtOTdOeHtP1m_5DAlIWTV6yazOoBlP_BKG58,
  websocket_client_ZhPy2_2UAj0pWmdohDN16DyMwmaeAkAVApkROSEoIrQ,
  ssg_detect_DCGLif7b0LpjeeLWFkoeqBCHXK3NYdKmnUr8OsQOt0Y,
  pwa_client_WyqWxI8CjoM6ZeyXY6GfzW9KWGr2CcrAuVn1egZuUWI,
  _0_routeRules_iQCBtc8UFu0kTz2BZTZyBmdlFAJM0VLWSmMrE_7GMTo
]