import validate from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._5fd424220c2ad888c9d8ce546ac753a9/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/app/middleware/auth.global.ts";
import disable_45vue_45transitions_45global from "/app/app/middleware/disable-vue-transitions.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._5fd424220c2ad888c9d8ce546ac753a9/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  disable_45vue_45transitions_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  guest: () => import("/app/app/middleware/guest.ts")
}